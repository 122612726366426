import React from "react";
import { Grid, Box, Typography } from "@mui/material";
import { PheonixBox } from "pheonixui";
import { useNavigate } from "react-router-dom";
import BackgroundA from "../assets/LOGOICON.png";
import "../App.css";
import { TEXT_MESSAGES } from "../const";
import PheonixAppBar from "./AppBar";

const Main: React.FC = () => {
  const navigate = useNavigate();
  const handleabout = () => {
    navigate("/about");
  };
  const handleproduct = () => {
    navigate("/product");
  };

  return (
    <div
      style={{ maxHeight: "100vh", backgroundColor: "#000000", width: "100%" }}
    >
      <PheonixAppBar />
      <Grid
        container
        alignItems="center"
        justifyContent="space-around"
        sx={{
          maxHeight: "100vh",
          paddingTop: { xs: "80px", md: "60px" },
          paddingLeft: { xs: "10px", md: "60px" },
          width: { lg: "80%" },
        }}
      >
        <Grid item md={1} xs={0} sx={{ display: { xs: "none", sm: "flex" } }}>
          <PheonixBox
            sx={{
              display: { xs: "none", sm: "flex" },
              flexDirection: "column",
              gap: 3,
              position: "relative",
            }}
          >
            <Box
              className="hover-sidemenu"
              sx={{
                writingMode: "vertical-lr",
                transform: "rotate(180deg)",
                color: "white",
                fontFamily: "Poppins",
                fontWeight: 400,
                fontSize: "16px",
                cursor: "pointer",
              }}
              onClick={handleabout}
            >
              {TEXT_MESSAGES.about}
            </Box>
            <Box
              className="hover-sidemenu"
              sx={{
                writingMode: "vertical-lr",
                transform: "rotate(180deg)",
                color: "white",
                fontFamily: "Poppins",
                fontWeight: 400,
                fontSize: "16px",
                cursor: "pointer",
              }}
              onClick={handleproduct}
            >
              {TEXT_MESSAGES.products}
            </Box>
          </PheonixBox>
        </Grid>
        <Grid
          item
          xs={1}
          sx={{
            display: "flex",
            justifyContent: "left",
            alignItems: "left",
            marginLeft: { lg: "-100px", sm: 0 },
          }}
        >
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontSize: { md: "386px", xs: "220px" },
              fontWeight: 400,
              color: "#000000",
              WebkitTextStroke: "1px #363636",
              WebkitTextFillColor: "#000000",
            }}
          >
            {TEXT_MESSAGES.i}
          </Typography>
        </Grid>

        <Grid
          item
          xs={10}
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            paddingLeft: "20px",
            maxWidth: { xl: "75%" },
            width: { md: "962px" },
            height: { md: "456px" },
          }}
        >
          <Box sx={{}}>
            <Typography
              sx={{
                color: "white",
                fontFamily: "Poppins",
                fontWeight: 400,
                fontSize: { xs: "24px", md: "64px" },
                textAlign: "left",
              }}
            >
              {TEXT_MESSAGES.maintext}{" "}
              <span style={{ color: "#9f9f9f", fontWeight: 700 }}>
                {TEXT_MESSAGES.create}
              </span>
              {TEXT_MESSAGES.subtext}{" "}
              <span style={{ color: "#E63125", fontWeight: 700 }}>
                {TEXT_MESSAGES.impact}
              </span>
              .
            </Typography>
          </Box>
        </Grid>
      </Grid>

      <Box
        component="img"
        src={BackgroundA}
        alt="Logo"
        sx={{
          position: "absolute",
          bottom: {
            lg: "60px",
            md: "56px",
            xl: "40px",
            sm: "56px",
            xs: "56px",
          },
          right: {
            xl: "100px",
            lg: "50px",
            md: "50px",
            sm: "50px",
            xs: "50px",
          },
          width: "40px",
          height: "42px",
        }}
      />
    </div>
  );
};

export default Main;
