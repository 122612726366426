import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Main from "./components/Main";
import Loading from "./components/Loading";
import About from "./components/About";
import Products from "./components/Products";
import Terms from "./components/Terms";
import Privacy from "./components/Privacy";
const App: React.FC = () => {
 
  return (
    <Routes>
      <Route path="/" element={<Loading />} />
      <Route path="/main" element={<Main />} />
      <Route path="/about" element={<About />} />
      <Route path="/product" element={<Products />} />
      <Route path="/terms" element={<Terms />}/>
      <Route path="/privacy" element={<Privacy />}/>
    </Routes>
  );
};

export default App;
