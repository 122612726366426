import React, { useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTheme, useMediaQuery } from "@mui/material";
import { PheonixBox, PheonixButton } from "pheonixui";
import BackgroundA from "../assets/LOGOICON.png";
import "../App.css";
import { TEXT_MESSAGES } from "../const";
import PheonixAppBar from "./AppBar";
import Vector from "../assets/Vector.png";
import rectangle from "../assets/Rectangle.png";
import ContactModal from "./ContactModal";

const About: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isLargeHeight = useMediaQuery("(min-height:800px)");
  const [contactModalOpen, setContactModalOpen] = useState(false);

  const handleproduct = () => {
    navigate("/product");
  };
  const handleContactModalOpen = () => {
    setContactModalOpen(true);
  };

  const handleContactModalClose = () => {
    setContactModalOpen(false);
  };

  return (
    <div
      style={{
        maxHeight: "100vh",
        backgroundColor: "#000000",
        width: "100%",
        overflow: "hidden",
      }}
    >
      <PheonixAppBar />
      <Grid
        container
        alignItems="center"
        justifyContent="space-around"
        sx={{
          maxHeight: "100vh",
          paddingTop: isMobile && isLargeHeight ? "40%" :{ xs: "20%",sm:"80px", md: "70px" },
          paddingLeft: { xs: "10px", md: "40px" },
          width: "100%",
        }}
      >
        <Grid item md={1} xs={0} sx={{ display: { xs: "none", sm: "flex" } }}>
          <PheonixBox
            sx={{
              display: { xs: "none", sm: "flex" },
              flexDirection: "column",
              gap: 3,
              position: "relative",
            }}
          >
            <span
              style={{
                writingMode: "vertical-lr",
                transform: "rotate(180deg)",
                color: "white",
                fontFamily: "Poppins",
                fontWeight: 700,
                fontSize: "18px",
              }}
            >
              {TEXT_MESSAGES.about}
            </span>
             <Box
                className="hover-sidemenu"
                sx={{
                  writingMode: "vertical-lr",
                  transform: "rotate(180deg)",
                  color: "white",
                  fontFamily: "Poppins",
                  fontWeight: 400,
                  fontSize: "16px",
                  cursor: "pointer",                  
                }}
                onClick={handleproduct}>
              {TEXT_MESSAGES.products}
          </Box>
          </PheonixBox>
        </Grid>
        <Grid
          item
          xs={10}
          md={11}
          sm={11}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-start",
            marginLeft: { lg: "-180px", md: 0, sm: 0 },
            position: "relative",
          }}
        >
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontSize: { md: "38px", xs: "18px" },
              fontWeight: 400,
              color: "#ffffff",
              marginLeft: { xs: "0px", sm: "20px", md: "20px", lg: "100px" },
              textAlign: "left",
            }}
          >
            {TEXT_MESSAGES.about}
          </Typography>
          <Box
            component="img"
            src={Vector}
            alt="line"
            sx={{
              marginLeft: { xs: "0px", sm: "20px", md: "20px", lg: "100px" },
              marginBottom: { md: "-150px", lg: "-50px" },
              zIndex: 1,
            }}
          />
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontSize: { md: "386px", xs: "220px" },
              fontWeight: 400,
              color: "#000000",
              WebkitTextStroke: "1px #363636",
              WebkitTextFillColor: "#000000",
              position: "relative",
              zIndex: 1,
              textAlign: "center",
              marginLeft: { xs: "0px", md: "0px", lg: "100px", sm: "20px" },
            }}
          >
            {TEXT_MESSAGES.u}
          </Typography>
          <Box
            sx={{
              backgroundColor: "#000000",
              width: { xs: "100%", md: "800px", sm: "95%", lg: "922px" },
              height: { xs: "250px", md: "352px", sm: "300px" },
              zIndex: 1,
              position: "absolute",
              top: { xs: "60%", sm: "50%", md: "50%", lg: "50%" },
              left: { xs: "50%", sm: "50%", md: "70%", lg: "60%" },
              transform: "translate(-50%, -50%)",
            }}
          >
            <Typography
              sx={{
                color: "white",
                fontFamily: "Poppins",
                fontWeight: 400,
                fontSize: { xs: "18px", sm: "36px", md: "36px" },
                textAlign: "left",
                textTransform: "uppercase",
                marginLeft: { md: "-100px", lg: "-170px", xs: "0px" },
                marginRight: { md: "150px", xs: 0 },
                marginBottom: { md: "50px", xs: "20px" },
                marginTop: { xs: "0px", sm: "40px", md: "0px" },
              }}
            >
              {TEXT_MESSAGES.abouttext}
            </Typography>
            <Typography
              sx={{
                color: "white",
                fontFamily: "Poppins",
                fontWeight: { xs: 200, sm: 400, md: 400 },
                fontSize: { xs: "14px", sm: "24px", md: "24px" },
                textAlign: "left",
                lineHeight: { xs: "26.6px", sm: "45.6px", md: "45.6px" },
                textTransform: "uppercase",
                marginLeft: { md: "-100px", lg: "-170px", xs: "0px" },
                marginRight: { md: "150px", xs: 0 },
                marginBottom: { md: "50px", xs: "20px" },
                letterSpacing: "2%",
              }}
            >
              {TEXT_MESSAGES.aboutmain}
            </Typography>
            <PheonixButton
              variant="contained"
              className="hover-button-form"
              label={TEXT_MESSAGES.CONTACT}
              sx={{
                width: { xs: "106px", sm: "155px" },
                height: { xs: "31px", sm: "46px" },
                textTransform: "none",
                backgroundColor: "#212121",
                color: "#BDBDBD",
                fontFamily: "Poppins",
                fontWeight: 400,
                letterSpacing: "2%",
                fontSize: { xs: "16px", sm: "24px", md: "24px" },
                marginLeft: { md: "-100px", lg: "-170px", xs: "0px" },
                zIndex: 2,
                position: "relative",
              }}
              onClick={handleContactModalOpen}
            />
            {!isMobile && (
              <img
                src={rectangle}
                style={{
                  height: "25px",
                  width: "52px",
                  marginLeft: "-30px",
                  marginTop: "30px",
                  transform: "rotate(-25deg)",
                  zIndex: 1,
                  position: "absolute",
                }}
              />
            )}
          </Box>
        </Grid>
      </Grid>

      <Box
        component="img"
        src={BackgroundA}
        alt="Logo"
        sx={{
          position: "absolute",
          bottom: {
            xs: "100px",
            sm: "100px",
          },
          right: {
            xl: "100px",
            lg: "50px",
            md: "50px",
            sm: "50px",
            xs: "50px",
          },
          width: "40px",
          height: "42px",
        }}
      />
      <Typography
        sx={{
          fontFamily: "Poppins",
          fontSize: { md: "32px", sm: "32px", xs: "18px" },
          fontWeight: 400,
          textAlign: "left",
          color: "rgba(255, 255, 255, 0.31)",
          marginLeft: { xs: "40px", md: "80px", lg: "50px" },
          bottom: {
            xs: "-60px",
            md: "20px",
            sm: "20px",
            lg: "20px",
            xl: "100px",
          },
          marginTop: { xs: "130px", sm: "90px", md: "-10px",lg:"-80px" },
          letterSpacing: { xs: "2px", sm: "8px" },
        }}
      >
        {TEXT_MESSAGES.deliver}
      </Typography>
      <ContactModal
        open={contactModalOpen}
        onClose={handleContactModalClose}
      />
    </div>
  );
};

export default About;
