import React, {useEffect} from "react";
import { useNavigate } from "react-router-dom";
import { useTheme, useMediaQuery } from "@mui/material";
import logo from "../assets/logo.webp";
import "../App.css";
import { content, TEXT_MESSAGES } from "../const";

const Loading: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const handleLoading = () => {
    navigate("/main");
  };
  useEffect(()=>{
    setTimeout(() => {
      navigate("/main");
    }, 12000);
    
  })

  return (
    <div className="container" onClick={handleLoading} >
      <div
        className="background-image"
        style={{
          fontFamily: "Poppins",
          fontSize: isMobile? "220px" : "386px",
          fontWeight: 400,
          color: "#000000",
          WebkitTextStroke: "1px #7A7A7A",
          WebkitTextFillColor: "#000000",
        }}
      >{TEXT_MESSAGES.a}
      </div>
      <img src={logo} alt="logo" className="logo" />
      <div className="footer">
        <div className="diagonal-bar">
          <div className="animate">
            {content.map((item, index) => (
              <span
                key={index}
                style={{
                  color: item === "●" ? "red" : "inherit",
                  fontFamily: "Roboto",
                  fontWeight: 400,
                  fontSize: isMobile ? "16px" : "18px",
                }}
              >
                {item}
              </span>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Loading;
