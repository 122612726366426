import React, { useState, MouseEvent } from "react";
import {
  AppBar,
  Box,
  Button,
  Grid,
  IconButton,
  Toolbar,
  Menu,
  MenuItem,
  ListItemText,
} from "@mui/material";
import ContactModal from "./ContactModal";
import { PheonixBox } from "pheonixui";
import { Menu as MenuIcon } from "@mui/icons-material";
import logo from "../assets/logo.webp";
import MenuImage from "../assets/menuicon.png";
import "../App.css";
import { TEXT_MESSAGES } from "../const";
import data from "../data.json";
import PheonixMenu from "./Menu";
import "../App.css";

const PheonixAppBar: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [productsMenuOpen, setProductsMenuOpen] = useState(false);
  const [contactModalOpen, setContactModalOpen] = useState(false);

  const handleMenuOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleProductsMenuOpen = () => {
    setProductsMenuOpen(true);
  };
  const handleProductMenuClose = () => {
    setProductsMenuOpen(false);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleContactModalOpen = () => {
    handleMenuClose();
    setContactModalOpen(true);
  };

  const handleContactModalClose = () => {
    setContactModalOpen(false);
  };

  const tabs = [
    { label: "ABOUT US", ref: "/about" },
    { label: "PRODUCTS", ref: "/product" },
    { label: "CONTACT US", onClick: handleContactModalOpen },
  ];

  return (
    <div
      style={{
        maxHeight: "100vh",
        backgroundColor: "#000000",
        width: "100%",
        overflow: "hidden",
      }}
    >
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        sx={{
          position: "absolute",
          top: 0,
          width: "100%",
          height: "89px",
          px: 2,
          display: { xs: "none", sm: "flex" },
        }}
      >
        <Grid item xs="auto">
        <Button
        className="hover-button"
            variant="contained"
            style={{
              textTransform: "none",
              backgroundColor: "#212121",
              color: "#ffffff",
              fontFamily: "Poppins",
              fontWeight: 400,
              fontSize: "14px",
              marginLeft: "20px",
              cursor:"pointer"
            }}
            onClick={handleContactModalOpen}
          >
            {TEXT_MESSAGES.contact}
          </Button>
        </Grid>

        <Grid item xs="auto">
          <Box
            component="img"
            src={logo}
            alt="Logo"
            sx={{
              width: { xs: "200px", md: "342px" },
              height: "77px",
              objectFit: "contain",
            }}
          />
        </Grid>

        <Grid item xs="auto">
          <PheonixBox
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
              marginRight: "20px",
            }}
          >
            <span
              style={{
                fontWeight: 400,
                color: "white",
                fontFamily: "Poppins",
                fontSize: "14px",
                marginRight: "40px",
              }}
            >
              {TEXT_MESSAGES.allproducts}
            </span>
            <Box
            className = "hover-button"
              component="img"
              src={MenuImage}
              alt="menu"
              sx={{
                width: "28px",
                height: "9px",
                marginRight: "20px",
                cursor: "pointer",
              }}
              onClick={handleProductsMenuOpen}
            />
          </PheonixBox>
        </Grid>
      </Grid>
      {productsMenuOpen && <PheonixMenu onClose={handleProductMenuClose} />}
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: "#0A0A0A",
          boxShadow: "none",
          display: { xs: "block", sm: "none", md: "none" },
        }}
      >
        <Toolbar>
          <IconButton className= "hover-button" color="inherit" onClick={handleMenuOpen} edge="start">
            <MenuIcon sx={{ color: "white" }} />
          </IconButton>
          <Box
            component="img"
            src={logo}
            alt="Logo"
            sx={{
              flexGrow: 1,
              display: "flex",
              justifyContent: "center",
              width: { xs: "265px", md: "200px" },
              height: "49px",
              objectFit: "contain",
            }}
          />
          <Box
            component="img"
            src={MenuImage}
            alt="menu"
            sx={{
              width: "28px",
              height: "9px",
              marginRight: "10px",
              marginLeft: "auto",
              cursor: "pointer",
            }}
            onClick={handleProductsMenuOpen}
          />
        </Toolbar>

        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
          sx={{
            width: "285px",
            marginTop: "30px",
            "& .MuiList-root": {
              paddingTop: 0,
              paddingBottom: 0,
              border: "none",
              boxShadow: "none",
              backgroundColor: "transparent",
            },
          }}
        >
          {tabs.map((tab, index) => (
            <MenuItem
              key={index}
              onClick={() => {
                if (tab.onClick) {
                  tab.onClick();
                } else if (tab.ref) {
                  window.location.href = tab.ref;
                }
              }}
              sx={{
                backgroundColor: "#313131",
                border: "none",
                boxShadow: "none",
                color: "#ffffff",
                fontWeight: 400,
                "&:hover": {
                  backgroundColor: "#ffffff !important",
                  color: "black",
                },
                "&.Mui-selected": {
                  backgroundColor: "#ffffff",
                  color: "black",
                },
                "&.Mui-selected:hover": {
                  backgroundColor: "#ffffff",
                },
              }}
            >
              <ListItemText
                primary={tab.label}
                primaryTypographyProps={{
                  fontWeight: 400,
                  fontSize: "16px",
                  fontFamily: "Poppins",
                }}
              />
            </MenuItem>
          ))}
        </Menu>
      </AppBar>
      <ContactModal open={contactModalOpen} onClose={handleContactModalClose} />
    </div>
  );
};
export default PheonixAppBar;
