import React, { useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { PheonixBox } from "pheonixui";
import BackgroundA from "../assets/LOGOICON.png";
import "../App.css";
import { TEXT_MESSAGES, sliderdata } from "../const";
import PheonixAppBar from "./AppBar";
import Vector from "../assets/Vector.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const CustomPrevArrow = (props: any) => {
  const { className, style, onClick } = props;
  const [hover, setHover] = useState(false);
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 2,
        cursor: "pointer",
        borderRadius: "50%",
        width: "40px",
        height: "40px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
      }}
      onClick={onClick}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <ArrowBackIcon
        style={{
          color: hover ? "#ffffff" : "#6C6B6B",
          fontSize: "24px",
          transition: "color 0.3s ease",
        }}
      />
    </div>
  );
};

const CustomNextArrow = (props: any) => {
  const { className, style, onClick } = props;
  const [hover, setHover] = useState(false);
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 2,
        cursor: "pointer",
        borderRadius: "50%",
        width: "40px",
        height: "40px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
      }}
      onClick={onClick}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <ArrowForwardIcon
        style={{
          color: hover ? "#ffffff" : "#6C6B6B",
          fontSize: "24px",
          transition: "color 0.3s ease",
        }}
      />
    </div>
  );
};

const SlickSlider: any = Slider;

const Products: React.FC = () => {
  const data = sliderdata;
  const navigate = useNavigate();
  const handleabout = () => {
    navigate("/about");
  };

  const sliderStyles = {
    ".slick-prev, .slick-next": {
      top: "100%",
      bottom: "10px",
      cursor: "pointer",
      zIndex: 2,
    },

    ".slick-prev": {
      top: "100%",
      left: "95%",
      right: "10%",
      "@media (max-width: 600px)": {
        left: "35%",
        bottom: "20px",
      },
    },
    ".slick-next": {
      top: "100%",

      "@media (max-width: 600px)": {
        right: "35%",
        bottom: "20px",
      },
    },
    ".slick-prev:before, .slick-next:before": {
      content: "''",
    },
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 900,
    arrows: true,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    focusonselect: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div
      style={{
        maxHeight: "100vh",
        backgroundColor: "#000000",
        width: "100%",
        overflow: "hidden",
      }}
    >
      <PheonixAppBar />
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        sx={{
          maxHeight: "100vh",
          paddingTop: { xs: "80px", md: "80px" },
          width: "100%",
          paddingLeft: { xs: 0, sm: "80px" },
        }}
      >
        <Grid item md={1} xs={0} sx={{ display: { xs: "none", sm: "flex" } }}>
          <PheonixBox
            sx={{
              display: { xs: "none", sm: "flex" },
              flexDirection: "column",
              gap: 3,
              position: "relative",
            }}
          >
            <Box
              className="hover-sidemenu"
              sx={{
                writingMode: "vertical-lr",
                transform: "rotate(180deg)",
                color: "white",
                fontFamily: "Poppins",
                fontWeight: 400,
                fontSize: "16px",
                cursor: "pointer",
              }}
              onClick={handleabout}
            >
              {TEXT_MESSAGES.about}
            </Box>
            <span
              style={{
                writingMode: "vertical-lr",
                transform: "rotate(180deg)",
                color: "white",
                fontFamily: "Poppins",
                fontWeight: 700,
                fontSize: "18px",
              }}
            >
              {TEXT_MESSAGES.products}
            </span>
          </PheonixBox>
        </Grid>
        <Grid
          item
          xs={12}
          md={11}
          sm={11}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            marginLeft: { lg: "-180px", md: 0, sm: 0 },
            position: "relative",
          }}
        >
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontSize: { md: "38px", xs: "18px" },
              fontWeight: 400,
              color: "#ffffff",
              marginLeft: { xs: "30px", md: "80px", lg: "20px" },
            }}
          >
            {TEXT_MESSAGES.products}
          </Typography>
          <Box
            component="img"
            src={Vector}
            alt="line"
            sx={{
              marginLeft: { xs: "30px", md: "80px", lg: "20px" },
              marginBottom: { md: "-150px", lg: "-140px" },
              zIndex: 1,
            }}
          />
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontSize: { md: "386px", xs: "220px" },
              fontWeight: 400,
              color: "#000000",
              WebkitTextStroke: "1px #363636",
              WebkitTextFillColor: "#000000",
              position: "relative",
              zIndex: 1,
              textAlign: "center",
              marginLeft: { xs: "30px", md: 0 },
            }}
          >
            {TEXT_MESSAGES.p}
          </Typography>

          <Box
            sx={{
              backgroundColor: "#000000",
              width: { xs: "80%", md: "800px", lg: "85%" },
              height: { xs: "250px", md: "352px", sm: "300px" },
              zIndex: 3,
              position: "absolute",
              top: { xs: "60%", md: "55%" },
              left: { xs: "50%", sm: "45%", md: "50%", lg: "50%" },
              transform: "translate(-50%, -50%)",
              ...sliderStyles,
            }}
          >
            <SlickSlider {...settings}>
              {data.map((item) => (
                <Box
                  key={item.id}
                  sx={{
                    textAlign: "center",
                    padding: 2,
                    backgroundColor: "#000000",
                    borderRadius: "8px",
                    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                    cursor: item.link ? "pointer" : "default",
                  }}
                  onClick={() => {
                    if (item.link) {
                      window.open(item.link, "_blank");
                    }
                  }}
                >
                  <Box
                    component="img"
                    src={item.image}
                    alt={item.title}
                    sx={{
                      width: "100%",
                      height: "200px",
                      objectFit: "cover",
                      borderRadius: "8px",
                      transition: "transform 0.3s ease-in-out",
                      gap: "20px",
                      "&:hover": {
                        transform: "scale(1.1)",
                      },
                    }}
                  />
                  <Typography
                    sx={{
                      fontWeight: 600,
                      fontSize: { xs: "18px", sm: "24px" },
                      marginTop: 2,
                      color: "#ffffff",
                      "&:hover": {
                        textDecoration: item.link ? "underline" : "default",
                        color: item.link ? "#E63125" : "rgba(159, 159, 159, 1)",
                      },
                    }}
                  >
                    {item.title}
                  </Typography>
                  <Typography
                    sx={{ fontWeight: 400, fontSize: "14px", color: "#ffffff" }}
                  >
                    {item.description}
                  </Typography>
                </Box>
              ))}
            </SlickSlider>
          </Box>
        </Grid>
      </Grid>
      <Box
        component="img"
        src={BackgroundA}
        alt="Logo"
        sx={{
          position: "absolute",
          bottom: {
            xs: "100px",
            sm: "100px",
          },
          right: {
            xl: "100px",
            lg: "50px",
            md: "50px",
            sm: "50px",
            xs: "50px",
          },
          width: "40px",
          height: "42px",
        }}
      />
      <Typography
        sx={{
          fontFamily: "Poppins",
          fontSize: { md: "32px", sm: "32px", xs: "18px" },
          fontWeight: 400,
          textAlign: "left",
          color: "rgba(255, 255, 255, 0.31)",
          marginLeft: { xs: "10px", md: "80px", lg: "50px" },
          bottom: {
            xs: "-60px",
            md: "20px",
            sm: "20px",
            lg: "20px",
          },
          marginTop: { xs: "130px", sm: "80px", md: "-10px" },
          letterSpacing: { xs: "2px", sm: "8px" },
        }}
      >
        {TEXT_MESSAGES.sales}
      </Typography>
    </div>
  );
};

export default Products;
