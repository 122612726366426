import React, { useState, useEffect } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTheme, useMediaQuery } from "@mui/material";
import { PheonixBox } from "pheonixui";
import PheonixAppBar from "./AppBar";
import "../App.css";
import { TEXT_MESSAGES } from "../const";
import Vector from "../assets/Vector.png";

const Privacy: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isLargeHeight = useMediaQuery("(min-height:800px)");

  const handleProduct = () => {
    navigate("/product");
  };
  const handleabout = () => {
    navigate("/about");
  };
  const [content, setContent] = useState("");

  useEffect(() => {
    fetch("/PrivacyContent.html")
      .then((response) => response.text())
      .then((data) => setContent(data));
  }, []);

  return (
    <div
      style={{
        maxHeight: "100vh",
        backgroundColor: "#000000",
        width: "100%",
      }}
    >
      <PheonixAppBar />

      <Grid
        container
        alignItems="center"
        justifyContent="space-around"
        sx={{
          maxHeight: "100vh",
          paddingTop:
            isMobile && isLargeHeight
              ? "10%"
              : { xs: "20%", sm: "80px", md: "70px" },
          paddingLeft: { xs: "10px", md: "40px" },
          width: "100%",
        }}
      >
        <Grid item md={1} xs={0} sx={{ display: { xs: "none", sm: "flex" } }}>
          <PheonixBox
            sx={{
              display: { xs: "none", sm: "flex" },
              flexDirection: "column",
              gap: 3,
              position: "relative",
            }}
          >
            <Box
              className="hover-sidemenu"
              sx={{
                writingMode: "vertical-lr",
                transform: "rotate(180deg)",
                color: "white",
                fontFamily: "Poppins",
                fontWeight: 400,
                fontSize: "16px",
                cursor: "pointer",
              }}
              onClick={handleabout}
            >
              {TEXT_MESSAGES.about}
            </Box>
            <Box
              className="hover-sidemenu"
              sx={{
                writingMode: "vertical-lr",
                transform: "rotate(180deg)",
                color: "white",
                fontFamily: "Poppins",
                fontWeight: 400,
                fontSize: "16px",
                cursor: "pointer",
              }}
              onClick={handleProduct}
            >
              {TEXT_MESSAGES.products}
            </Box>
          </PheonixBox>
        </Grid>
        <Grid
          item
          xs={10}
          md={11}
          sm={11}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-start",
            marginLeft: { lg: "-180px", md: 0, sm: 0 },
            position: "relative",
          }}
        >
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontSize: { md: "38px", xs: "18px" },
              fontWeight: 400,
              color: "#ffffff",
              marginLeft: { xs: "20px", sm: "20px", md: "20px", lg: "80px" },
              textAlign: "left",
              mt: 5,
            }}
          >
            {TEXT_MESSAGES.privacy}
          </Typography>
          <Box
            component="img"
            src={Vector}
            alt="line"
            sx={{
              marginLeft: { xs: "20px", sm: "20px", md: "20px", lg: "80px" },
              marginBottom: { md: "-150px", lg: "-50px" },
              zIndex: 1,
            }}
          />
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontSize: { md: "386px", xs: "220px" },
              fontWeight: 400,
              color: "rgba(0, 0, 0, 0.1)",
              WebkitTextStroke: "1px #363636",
              WebkitTextFillColor: "#000000",
              position: "relative",
              zIndex: 1,
              textAlign: "center",
              marginLeft: { xs: 0, md: "0%", lg: "5%" },
            }}
          >
            {TEXT_MESSAGES.u}
          </Typography>
          <Box
            sx={{
              zIndex: 2,
              position: "absolute",
              top: "20%",
              left: { xs: "0%", sm: "0%", md: "0%", lg: "5%" },
            }}
          >
            <div dangerouslySetInnerHTML={{ __html: content }} />
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default Privacy;
