import React, { useState } from "react";
import {
  Box,
  IconButton,
  Typography,
  TextField,
  Stack,
  Modal,
  InputAdornment,
} from "@mui/material";
import { api } from "../api";
import axios from "axios";
import CloseIcon from "@mui/icons-material/Close";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { PheonixButton, PheonixSnackbar } from "pheonixui";
import { TEXT_MESSAGES } from "../const";
import user from "../assets/user.png";
import mail from "../assets/mail.png";
import notesimg from "../assets/Group.png";

interface ContactModalProps {
  open: boolean;
  onClose: () => void;
}

const ContactModal: React.FC<ContactModalProps> = ({ open, onClose }) => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [fullName, setFullName] = useState("");
  const [error, setError] = useState(false);

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const nameRegex = /^[A-Za-z\s]*$/;
    setFullName(value);
    if (value && !nameRegex.test(value)) {
      setError(true);
    } else {
      setError(false);
    }
  };
  const [email, setEmail] = useState("");
  const [Emailerror, setEmailError] = useState(false);

  const handleemailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleEmailBlur = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setEmailError(true);
    } else {
      setEmailError(false);
    }
  };

  const handleEmailFocus = () => {
    setEmailError(false);
  };

  const [notes, setNotes] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [alertmessage, setalertmessage] = useState("");
  const [formmessage, setformmessage] = useState("");
  const handleSnackbarOpen = () => {
    setSnackbarOpen(true);
  };
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };
  const handlePhoneNumberChange = (value: string) => {
    setPhoneNumber(value);
  };
  const handleSubmit = async () => {
    if(!fullName || !(email || phoneNumber)){
      setformmessage(TEXT_MESSAGES.FORMERR);
      setTimeout(() => {
        setformmessage("");
      }, 3000);
      return;
    }
    const query = `
        mutation addUser(
          $Name: String!,
          $phone_number: String!,
          $email: String!,
          $notes: String
        ) {
          addUser(
            Name: $Name,
            phone_number: $phone_number,
            email: $email,
            notes: $notes
          ) {
            success
            message
            reply
          }
        }
      `;

    const variables = {
      Name: fullName,
      phone_number: phoneNumber,
      email: email,
      notes: notes,
    };

    try {
      const response = await axios.post(api.baseUrl, {
        query,
        variables,
      });
      const { success, reply } = response.data.data.addUser;
      if (success) {
        setalertmessage(reply);
        onClose();
        setFullName("");
        setEmail("");
        setPhoneNumber("");
        setNotes("");
        handleSnackbarOpen();
        setTimeout(() => {
          handleSnackbarClose();
        }, 3000);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const resetFields = () => {
    setFullName("");
    setEmail("");
    setPhoneNumber("");
    setNotes("");
    setError(false);
    setEmailError(false);
    setformmessage("");
  };

  const handleClose = () => {
    resetFields();
    onClose();
  };

  return (
    <div>
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: {
              xs: "85%",
              sm: "80%",
              md: "50%",
            },
            maxWidth: "446px",
            height: {
              xs: formmessage? "80vh":"75vh",
              sm: formmessage?"568px":"548px",
            },
            backgroundColor: "#353535",
            boxShadow: 24,
            fontFamily: "Poppins",
            padding: 2,
          }}
        >
          <Box
            sx={{
              position: "relative",
              mb: 1,
              padding: { xs: "12px", sm: "24px" },
              borderBottom: "1px solid #646464",
            }}
          >
            
            <Typography
              sx={{
                color: "#ffffff",
                fontWeight: "600",
                fontSize: { xs: "14px", sm: "20px" },
                fontFamily: "Poppins",
                mb: 1,
              }}
            >
              {TEXT_MESSAGES.CONTACT}
            </Typography>
            <IconButton
              sx={{
                position: "absolute",
                top: { xs: 5, sm: 10 },
                right: 5,
                color: "#FFFFFF",
              }}
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>

            <Typography
              sx={{
                fontWeight: 400,
                fontSize: { xs: "12px", sm: "14px" },
                color: "#DEDEDE",
                fontFamily: "Poppins",
                mb: { xs: 1, sm: 2 },
              }}
            >
              {TEXT_MESSAGES.contactdesc}
            </Typography>
          </Box>
          <Stack spacing={3} sx={{ padding: { xs: "16px", sm: "32px 24px" } }}>
          {formmessage && <span style={{
              color: "orange",
              fontWeight: "400",
              fontSize: "12px",
              fontFamily: "Poppins",
            }}>{formmessage}</span>}

            <TextField
              fullWidth
              variant="filled"
              placeholder="Full Name"
              value={fullName}
              onChange={handleNameChange}
              error={error}
              helperText={error ? "Name can contain only letters" : ""}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Box
                      component="img"
                      src={user}
                      alt="icon"
                      sx={{
                        height: "20px",
                        width: "20px",
                        marginRight: "4px",
                        mb: 2,
                      }}
                    />
                  </InputAdornment>
                ),
              }}
              sx={{
                height: "44px",
                "& .MuiFilledInput-root": {
                  backgroundColor: "#ffffff",
                  borderRadius: 0,
                  "&:hover": {
                    backgroundColor: "#ffffff",
                  },
                  "&.Mui-focused": {
                    backgroundColor: "#ffffff",
                  },
                },
                "& .MuiFilledInput-input::placeholder": {
                  color: "#666666",
                  fontWeight: 400,
                  opacity: 1,
                },
                "& .MuiFilledInput-input": {
                  padding: "10px",
                  fontSize: { xs: "14px", sm: "16px" },
                },
                "& .MuiFilledInput-underline:before, & .MuiFilledInput-underline:after":
                  {
                    borderBottom: "none",
                  },
                "& .MuiFormHelperText-root": {
                  marginTop: "1px",
                  marginLeft: "0px",
                },
                "& .MuiFormHelperText-root.Mui-error": {
                  color: "orange",
                  fontSize: "12px",
                },
              }}
            />
            <PhoneInput
              country={"in"}
              value={phoneNumber}
              onChange={handlePhoneNumberChange}
              inputStyle={{
                backgroundColor: "#ffffff",
                color: "currentColor",
                width: "100%",
                height: "44px",
                fontSize: "14px",
                borderRadius: 0,
              }}
              dropdownStyle={{
                backgroundColor: "#ffffff",
                color: "currentColor",
              }}
              placeholder="Mobile Number"
            />
            <TextField
              fullWidth
              variant="filled"
              placeholder="Email"
              value={email}
              onChange={handleemailChange}
              onFocus={handleEmailFocus}
              onBlur={handleEmailBlur}
              error={Emailerror}
              helperText={Emailerror ? "Please enter a valid email" : ""}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Box
                      component="img"
                      src={mail}
                      alt="icon"
                      sx={{
                        height: "20px",
                        width: "20px",
                        marginRight: "4px",
                        mb: 2,
                      }}
                    />
                  </InputAdornment>
                ),
              }}
              sx={{
                height: "44px",
                "& .MuiFilledInput-root": {
                  backgroundColor: "#ffffff",
                  borderRadius: 0,
                  "&:hover": {
                    backgroundColor: "#ffffff",
                  },
                  "&.Mui-focused": {
                    backgroundColor: "#ffffff",
                  },
                },
                "& .MuiFilledInput-input::placeholder": {
                  color: "#666666",
                  fontWeight: 400,
                  opacity: 1,
                },
                "& .MuiFilledInput-input": {
                  padding: "10px",
                  fontSize: { xs: "14px", sm: "16px" },
                },
                "& .MuiFilledInput-underline:before, & .MuiFilledInput-underline:after":
                  {
                    borderBottom: "none",
                  },
                "& .MuiFormHelperText-root": {
                  marginTop: "1px",
                  marginLeft: "0px",
                },
                "& .MuiFormHelperText-root.Mui-error": {
                  color: "orange",
                  fontSize: "12px",
                },
              }}
            />
            <TextField
              variant="filled"
              multiline
              rows={2}
              fullWidth
              placeholder="Notes"
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Box
                      component="img"
                      src={notesimg}
                      alt="icon"
                      sx={{
                        height: "20px",
                        width: "20px",
                        mb: 4,
                        marginRight: "4px",
                      }}
                    />
                  </InputAdornment>
                ),
              }}
              sx={{
                "& .MuiFilledInput-root": {
                  backgroundColor: "#ffffff",
                  borderRadius: 0,
                  "&:hover": {
                    backgroundColor: "#ffffff",
                  },
                  "&.Mui-focused": {
                    backgroundColor: "#ffffff",
                  },
                },
                "& .MuiFilledInput-input::placeholder": {
                  color: "#666666",
                  fontWeight: 400,
                  opacity: 1,
                  paddingLeft:"10px"
                },
                "& .MuiFilledInput-input": {
                  padding: 0,
                  fontSize: { xs: "14px", sm: "16px" },
                },
                "& .MuiFilledInput-underline:before, & .MuiFilledInput-underline:after":
                  {
                    borderBottom: "none",
                  },
                height: "70px",
              }}
            />
          </Stack>
          <PheonixButton
          className="hover-button-form"
            fullWidth
            label={TEXT_MESSAGES.callback}
            sx={{
              width: "90%",
              mt: { xs: 2, sm: 1 },
              left: "5%",
              right: "5%",
              backgroundColor: "#191818",
              color: "#ffffff",
              textTransform: "none",
              fontSize: { xs: "14px", sm: "16px" },
            }}
            onClick={handleSubmit}
          />
        </Box>
      </Modal>
      <PheonixSnackbar
        vertical="top"
        horizontal="right"
        open={snackbarOpen}
        onClose={handleSnackbarClose}
        message={alertmessage}
        severity="error"
        backgroundColor="#353535"
        color="#ffffff"
        width="627px"
        height="62px"
        sx={{
          fontFamily: "Poppins",
          fontWeight: 400,
          fontSize: { xs: "12px", sm: "16px" },
          lineHeight: { xs: "18px", sm: "24px" },
          mt: 8,
        }}
      />
    </div>
  );
};

export default ContactModal;
